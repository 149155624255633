import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { widgetDefaults } from '../../../../utils/bi/consts';

export type OnSubmit = () => void;

export function createOnSubmitAction({
  getControllerState,
  context: { wixSdkAdapter, formApi, service, slotAvailability },
}: ActionFactoryParams<FormState, FormContext>): OnSubmit {
  return async () => {
    const bookingResponse = await formApi.book(service!, slotAvailability!);
    return wixSdkAdapter.navigateToBookingsCheckout(
      bookingResponse.booking!,
      widgetDefaults.pageName,
    );
  };
}
