import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/http';
import { ListSlotsResponse } from '@wix/ambassador-calendar-server/http';
import { CatalogData } from '../../api/types';
import { mapCatalogServiceToService, Service } from '../mappers/service.mapper';
import { TFunction } from '../../components/BookingsForm/controller';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApi } from '../../api/FormApi';

export const DEFAULT_LOCALE = 'en-US';

export type FormContext = {
  service?: Service;
  isPricingPlanInstalled?: boolean;
  hasCoupon?: boolean;
  businessInfo?: BusinessInfoBase;
  slotAvailability?: SlotAvailability;
  activeFeatures?: GetActiveFeaturesResponse;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  dateAndTimeFormatter?: DateTimeFormatter;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
};

export const createFormContext = ({
  catalogData,
  listSlots,
  slotAvailability,
  isPricingPlanInstalled,
  pricingPlanDetails,
  t,
  settings,
  wixSdkAdapter,
  formApi,
}: {
  catalogData: CatalogData;
  listSlots?: ListSlotsResponse;
  slotAvailability: SlotAvailability;
  isPricingPlanInstalled: boolean;
  pricingPlanDetails: Optional<PaidPlans>;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): FormContext => {
  const service = mapCatalogServiceToService({
    catalogData,
    slotAvailability,
    pricingPlanDetails,
    t,
    listSlots,
  });
  const locale =
    catalogData.businessInfo?.dateRegionalSettingsLocale ?? DEFAULT_LOCALE;
  const dateAndTimeFormatter = new DateTimeFormatter(locale);

  return {
    activeFeatures: catalogData.activeFeatures,
    service,
    businessInfo: catalogData.businessInfo,
    slotAvailability,
    isPricingPlanInstalled,
    t,
    settings,
    dateAndTimeFormatter,
    wixSdkAdapter,
    formApi,
  };
};
