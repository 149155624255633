import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';

export type FormState = {
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  selectedPaymentMethod?: any;
  userDetails?: Member;
  errors: any[];
};

export const createInitialState = ({
  userDetails,
  pricingPlanDetails,
}: {
  userDetails?: Optional<Member>;
  pricingPlanDetails?: PaidPlans;
}): FormState => {
  return {
    pricingPlanDetails,
    userDetails,
    selectedPaymentMethod: undefined,
    numberOfParticipants: 1,
    errors: [],
  };
};
