import { FormContext } from '../../../../utils/context/contextFactory';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import {
  getCourseDuration,
  getFormattedDuration,
  SlotDuration,
} from '../../../../utils/duration/duration';
import settingsParams from '../../settingsParams';
import {
  Service,
  Location,
  PaymentType,
  isServiceTypeIsCourse,
} from '../../../../utils/mappers/service.mapper';
import { TFunction } from '../../controller';

import { ServiceLocationType } from '@wix/bookings-uou-types';

export const STAFF_MEMBER_DELIMITER = ' , ';
export const DEFAULT_LOCALE = 'en-US';

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime: string;
  duration: SlotDuration;
  staffMemberNames: string;
  location: string;
  sectionTitle: string;
  videoConferenceBadgeText: string;
  actionLabel: string;
};

export function createBookingDetailsViewModel({
  context,
}: ViewModelFactoryParams<FormState, FormContext>): BookingDetailsViewModel {
  const { slotAvailability, service, t, settings, dateAndTimeFormatter } =
    context;

  const serviceName = service!.name!;
  const slot = slotAvailability?.slot!;

  const startDate = slot.startDate!;
  const endDate = slot.endDate!;
  const dateAndTimeFormat = dateAndTimeFormatter!.formatDateAndTime(startDate);
  const isCourse = isServiceTypeIsCourse(service?.type!);
  const dateAndTime =
    isCourse && isServiceHasMultipileSessions(service)
      ? t('app.booking-details.course.date-and-time.starts.text', {
          date: dateAndTimeFormat,
        })
      : dateAndTimeFormat;
  const duration = isCourse
    ? getCourseDuration(service!, t)
    : getFormattedDuration({
        startDate,
        endDate,
        t,
      });

  const staffMemberNames = service?.staffMembers
    ?.map((staffMember) => staffMember.name)
    .join(STAFF_MEMBER_DELIMITER)!;
  const location = getLocationText(service?.location!, t);

  const shouldShowVideoConferenceBadge = settings.get(
    settingsParams.videoConferenceBadgeVisibility,
  );

  const videoConferenceBadgeText =
    shouldShowVideoConferenceBadge && service?.videoConferenceProviderId
      ? 'app.booking-details.video-conference-available-online'
      : '';

  const sectionTitle = 'app.booking-details.title';
  const actionLabel = getActionLabel(service!);

  return {
    serviceName,
    dateAndTime,
    duration,
    staffMemberNames,
    location,
    sectionTitle,
    videoConferenceBadgeText,
    actionLabel,
  };
}

export const isServiceHasMultipileSessions = (service?: Service) => {
  return service?.totalNumberOfSessions && service?.totalNumberOfSessions > 1;
};

const getActionLabel = (service: Service): string => {
  if (service?.isPendingApprovalFlow) {
    return service.actionLabels?.bookingRequestApprovalLabel!;
  } else if (canOnlyBePaidOnline(service?.paymentTypes!)) {
    return service.actionLabels?.onlinePaymentLabel!;
  } else {
    return service.actionLabels?.offlinePaymentLabel!;
  }
};

const canOnlyBePaidOnline = (paymentTypes: PaymentType[]) =>
  paymentTypes?.length === 1 && paymentTypes?.includes(PaymentType.ONLINE);

const getLocationText = (location: Location, t: TFunction): string => {
  switch (location?.locationType) {
    case ServiceLocationType.OWNER_BUSINESS:
      return location?.name!;
    case ServiceLocationType.OWNER_CUSTOM:
      return location?.address!;
    case ServiceLocationType.CUSTOM:
      return t('app.booking-details.locations.client-place.text');
    default:
      return '';
  }
};
