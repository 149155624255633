import {
  CreateControllerFn,
  ControllerParams,
  ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import {
  createInitialState,
  FormState,
} from '../../utils/state/initialStateFactory';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import { createFormViewModel, FormViewModel } from './ViewModel/viewModel';
import {
  createFormContext,
  FormContext,
} from '../../utils/context/contextFactory';
import { createFormActions, FormActions } from './Actions/actions';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FormApi } from '../../api/FormApi';
import { createDummyCatalogData } from '../../api/DummyData/dummyCatalogData';
import {
  getActiveSchedule,
  getServiceType,
  isServiceTypeIsCourse,
} from '../../utils/mappers/service.mapper';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  let rerender: () => Promise<void> = async () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        settings,
      } = flowAPI;

      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const formApi = new FormApi({ wixSdkAdapter });

      const {
        catalogData,
        listSlots,
        userDetails,
        slotAvailability,
        pricingPlanDetails,
        isPricingPlanInstalled,
      } = wixSdkAdapter.isEditorMode()
        ? createDummyData(flowAPI)
        : await fetchInitData({ formApi, controllerConfig, wixSdkAdapter });

      const initialState: FormState = createInitialState({
        userDetails,
        pricingPlanDetails,
      });
      const formContext: FormContext = createFormContext({
        catalogData,
        listSlots,
        slotAvailability,
        isPricingPlanInstalled,
        pricingPlanDetails,
        t,
        settings,
        wixSdkAdapter,
        formApi,
      });

      const { render } = await createControlledComponent<
        FormState,
        FormActions,
        FormViewModel,
        FormContext
      >({
        controllerConfig,
        initialState,
        viewModelFactory: createFormViewModel,
        actionsFactory: createFormActions,
        context: formContext,
      });

      rerender = render;
    },
    updateConfig() {
      rerender();
    },
  };
};

const createDummyData = (flowApi: ControllerFlowAPI) => {
  return {
    slotAvailability: {},
    listSlots: {},
    catalogData: createDummyCatalogData(flowApi),
    userDetails: undefined,
    pricingPlanDetails: undefined,
    isPricingPlanInstalled: false,
  };
};

const fetchInitData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
}) => {
  const serviceId = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.SERVICE_ID,
  );
  const slotAvailabilityParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  const slotAvailability: SlotAvailability = slotAvailabilityParam
    ? JSON.parse(atob(slotAvailabilityParam))
    : undefined;
  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const isLoggedInUser = controllerConfig.wixCodeApi.user.currentUser.loggedIn;
  const shouldGetPricingPlanDetails = isLoggedInUser && startTime;

  const [catalogData, userDetails, pricingPlanDetails, isPricingPlanInstalled] =
    await Promise.all([
      formApi.getCatalogData({ serviceId, resourceId }),
      isLoggedInUser ? formApi.getMemberDetails() : undefined,
      shouldGetPricingPlanDetails
        ? formApi.getPricingPlanDetails({
            serviceId,
            startTime,
          })
        : undefined,
      wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    ]);

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  const isCourse = isServiceTypeIsCourse(type);
  const listSlots =
    isCourse && firstSessionStart && lastSessionEnd
      ? await formApi.getSlots({
          firstSessionStart,
          lastSessionEnd,
          scheduleId,
        })
      : {};

  return {
    catalogData,
    listSlots,
    userDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
  };
};

export default createController;
