import { FormField } from '@wix/ambassador-services-catalog-server/http';
import { CatalogData } from '../../api/types';
import { DisplayPropertiesFieldOption } from '@wix/ambassador-wix-form-builder-web/src/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  createAddressField,
  createFormField,
  getFieldType,
} from './service-form-field.mapper';
import { TFunction } from '../../components/BookingsForm/controller';
import { FormFieldViewInfoFieldType, FormView } from '@wix/forms-ui/types';
import { emailRegex, phoneRegex } from '@wix/wix-js-validations';

export const createFormView = ({
  catalogData,
  availability,
  showAddress,
  t,
}: {
  catalogData: CatalogData;
  t: TFunction;
  showAddress?: boolean;
  availability?: SlotAvailability;
}): FormView => {
  const form = catalogData.service.form!;
  const { customFields = [] } = form;

  const { isSMSReminderEnabled = false } = catalogData.businessInfo;

  const phoneFieldType = isSMSReminderEnabled
    ? FormFieldViewInfoFieldType.PHONE_COUNTRY_CODE
    : FormFieldViewInfoFieldType.PHONE;

  const maxParticipantsPerBooking =
    catalogData?.service?.service?.policy?.maxParticipantsPerBooking ?? 0;

  const fields = [
    createFormField({
      field: form.name!,
      fieldType: FormFieldViewInfoFieldType.TEXT,
    }),
    createFormField({
      field: form.email!,
      fieldType: FormFieldViewInfoFieldType.EMAIL,
      validations: {
        pattern: emailRegex.source,
      },
    }),
    createFormField({
      field: form.phone!,
      fieldType: phoneFieldType,
      validations: isSMSReminderEnabled ? { pattern: phoneRegex.source } : {},
    }),
    ...(isSMSReminderEnabled
      ? [
          createFormField({
            field: { label: t('app.booking-form.fields.sms-reminder.label') },
            fieldType: FormFieldViewInfoFieldType.CHECKBOX,
          }),
        ]
      : []),
    ...(shouldShowNumberOfParticipants(maxParticipantsPerBooking, availability)
      ? [
          createFormField({
            field: form.numberOfParticipants!,
            fieldType: FormFieldViewInfoFieldType.DROP_DOWN,
            displayProperties: {
              options: getNumberOfParticipantsOptions(
                maxParticipantsPerBooking,
                availability,
              ),
              defaultValue: '1',
            },
          }),
        ]
      : []),
    ...(showAddress && form.address ? [createAddressField(form.address!)] : []),
    ...customFields.map((field: FormField) =>
      createFormField({
        field,
        fieldType: getFieldType(field.valueType!),
      }),
    ),
  ];
  return {
    formId: form.id,
    fields,
  };
};

const getNumberOfParticipantsOptions = (
  maxParticipantsPerBooking: number,
  availability?: SlotAvailability,
): DisplayPropertiesFieldOption[] => {
  const maxOption = getMaxNumberOfParticipants({
    availability,
    maxParticipantsPerBooking,
  });
  return Array.from({ length: maxOption }, (_, key) => ({
    value: `${key + 1}`,
    label: `${key + 1}`,
  }));
};

const shouldShowNumberOfParticipants = (
  maxParticipantsPerBooking: number,
  availability?: SlotAvailability,
) => {
  return (
    getMaxNumberOfParticipants({ availability, maxParticipantsPerBooking }) > 1
  );
};

const getMaxNumberOfParticipants = ({
  availability,
  maxParticipantsPerBooking,
}: {
  maxParticipantsPerBooking: number;
  availability?: SlotAvailability;
}) => {
  const openSpots = availability?.openSpots ?? 0;
  return Math.min(maxParticipantsPerBooking, openSpots);
};
