import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';

export type FormActions = {
  onSubmit: OnSubmit;
};

export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormActions => ({
  onSubmit: createOnSubmitAction(actionFactoryParams),
});
